<template>
  <div class="topBar">
    <Content class="topBar-content">
      <div class="logo">
        <SVGLogo />
      </div>

      <ul>
        <li v-for="(page, i) in navigations" :key="i">
          <router-link :to="{ name: page.viewName }">{{ page.name }}</router-link>
        </li>
      </ul>

      <div class="user">
        <p class="name">{{ user.firstName }} {{ user.lastName }}</p>
      </div>
    </Content>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import Content from './Content'
import SVGLogo from '@/assets/lifebloom-logo.svg'

export default {
  name: 'TopBar',
  components: {
    Content, SVGLogo
  },
  computed: {
    ...mapGetters({
      user: 'auth/user',
      navigations: 'navMain'
    })
  }
}
</script>

<style lang="scss" scoped>
.topBar{
  width: 100%;
  height: 100px;
  background: $white-color;
  box-shadow: $box-shadow;
  margin-bottom: 1rem;

  .topBar-content{
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .logo {
      width: 71px;
      flex-shrink: 0;
    }

    ul{
      display: flex;

      li{
        padding: 0 10px;
        font-weight: 420;
        font-size: 1.1rem;

        .router-link-active{
          color: $main-color;
        }
      }
    }

    .user{
      .name{
        font-size: 1.1rem;
        font-weight: 420;
      }
    }
  }
}
</style>
