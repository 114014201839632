<template>
  <div class="content">
    <slot />
  </div>
</template>

<script>
export default {
  name: 'Content'
}
</script>

<style lang="scss" scoped>
.content{
  margin: 0px 32px;
}
</style>
