<template>
  <div class="card">
    <slot />
  </div>
</template>

<script>
export default {
  name: 'Card'
}
</script>

<style lang="scss" scoped>
.card{
  background: $white-color;
  border-radius: $border-radius;
  box-shadow: $box-shadow;
  padding: 20px;
}
</style>
