<template>
  <div class="page">
    <TopBar />

    <div class="page-content">
      <slot />
    </div>
  </div>
</template>

<script>
import TopBar from './TopBar'

export default {
  name: 'Page',
  components: {
    TopBar
  }
}
</script>

<style lang="scss" scoped>
.page{
  padding-bottom: 40px;
}
</style>
